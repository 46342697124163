@use "~ag-grid-community/styles" as ag;
@import "@inscopix/ideas-eui/src/themes/amsterdam/colors_light";
@import "@inscopix/ideas-eui/src/global_styling/variables/size";
@import "@inscopix/ideas-eui/src/global_styling/variables/borders";

$colorBlue: #60a7dc;

html,
body,
#root {
  min-height: 100vh;
  width: 100%;
}

.amplify-tabs {
  display: none !important;
}

.euiModal__flex {
  max-height: 90vh !important;
}

[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: #3d97cd;
  --amplify-components-button-primary-hover-background-color: #006097;
  --amplify-components-authenticator-modal-background-color: #eee;
  --amplify-components-authenticator-max-width: 100%;
}

.amplify-divider--label::after {
  background-color: #eeeeee;
}

@include ag.grid-styles(
  (
    theme: balham,
  )
);

@include ag.grid-styles(
  (
    theme: ag-theme-balham-cell-borders,
    extend-theme: balham,
    cell-horizontal-border: solid var(--ag-secondary-border-color),
  )
);

.ag-theme-balham-cell-borders {
  --ag-header-foreground-color: black;
  // Match the primary background color used by EUI
  --ag-selected-row-background-color: #e6f1fa;
  .ag-header-cell,
  .ag-header-group-cell-with-group {
    border-right: 1px solid var(--ag-border-color);
  }
  .ag-pinned-left-header {
    border-right: 2px solid var(--ag-border-color);
  }
  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(
      .ag-cell-range-single-cell
    ) {
    border-right: 2px solid var(--ag-border-color);
  }

  // only display menu button on header hover to save space
  .ag-header-cell-menu-button {
    display: none;
  }
  .ag-header-cell:hover .ag-header-cell-menu-button {
    display: inline;
  }
  .ag-header-group-cell:hover .ag-header-cell-menu-button {
    display: inline;
    opacity: 1;
  }

  .ag-cell {
    display: flex;
    align-items: center;
  }

  // Match popup editor background from AG Grid v30
  .ag-popup-editor {
    background: var(--ag-background-color);
    background-color: var(--ag-control-panel-background-color);
  }
}

.showPointer {
  cursor: pointer;
}

.noHover {
  pointer-events: none;
}

.euiFilterButton--hasIcon .euiButtonEmpty__content {
  justify-content: center !important;
}
.euiFilterButton-hasActiveFilters {
  background-color: rgba(0, 119, 204, 0.1) !important;
}

.euiTableCellContent__text {
  word-break: initial !important;
}

img[class$="-euiImage-isFullScreen"] {
  cursor: zoom-out !important;
}
