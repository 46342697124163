.vega-menu-clone {
  list-style: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px;
  z-index: 1000;
  background: white;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  color: #1b1e23;
  border: 1px solid #aaa;
  border-radius: 999px;
  opacity: 0.2;
  transition: opacity 0.4s ease-in;
  outline: none;
  cursor: pointer;
  line-height: 0px;
}

.vega-menu-clone:hover,
.vega-menu-clone:focus {
  opacity: 1 !important;
  transition: opacity 0.2s ease;
}

.vega-menu-clone-actions {
  padding: 2px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #d9d9d9;
  background: white;
  animation-duration: 0.15s;
  animation-name: scale-in;
  animation-timing-function: cubic-bezier(0.2, 0, 0.13, 1.5);
  text-align: left;
  margin: 0px;
}

.vega-menu-clone-actions .vega-menu-clone-menu-item {
  padding: 8px 16px;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  color: #434a56;
  text-decoration: none;
}

.vega-menu-clone-actions .vega-menu-clone-menu-item:hover {
  cursor: pointer;
  background-color: #f7f7f9;
  color: black;
}
