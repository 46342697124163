.ProjectCards {
  .euiTableRow {
    .showOnHover {
      opacity: 0;
      transition: opacity 300ms;
    }
    &.euiTableRow-isSelected {
      background-color: #383c43;
      &:hover {
        background-color: #1c1e21;
      }
      .euiTableRowCell,
      .euiButtonIcon--text {
        color: #fff;
      }
      .showOnHover {
        opacity: 1;
      }
    }
    &:hover {
      .showOnHover {
        opacity: 1;
      }
    }
  }
}
